import LoadingButton from '@atlaskit/button/loading-button';
import Button from '@atlaskit/button/standard-button';
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { siteAPIs } from '@root/services';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import Form from '@atlaskit/form';
import useSnackbar from '@root/hooks/useSnackbar';
import { noop } from '../../utils/common';
import UserManagerContext from './UserManagerContext';
import { SelectMultipleField } from '../common/select';
import { EmailField } from '../common/modal';

export default function InviteUserDialog({
  optionSites,
  siteDefault,
  onClose = noop,
}) {
  const { t } = useTranslation();
  const { loadUsers } = useContext(UserManagerContext);

  const { openSuccessSnackbar, openErrorSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [siteAccess, setSiteAccess] = useState([siteDefault]);

  const handleInviteNewUser = async () => {
    try {
      setIsInvitingUser(true);
      const siteIds = siteAccess.map((site) => site.value);
      newRelicUtils.addPageAction(PAGE_ACTION.userManagement.invite, {
        siteIds: siteIds.join(','),
        targetEmail: email,
      });
      const body = {
        email,
        siteIds,
      };
      const response = await siteAPIs.inviteUserToManySites(body);
      if (response.status === 200) {
        await loadUsers();
        openSuccessSnackbar('snackbar.inviteSiteUserOk');
      } else {
        openErrorSnackbar('snackbar.inviteSiteUserFailed');
      }
    } catch (inviteUserError) {
      openErrorSnackbar('snackbar.inviteSiteUserFailed');
    } finally {
      setEmail('');
      onClose();
      setIsInvitingUser(false);
    }
  };

  return (
    <ModalTransition>
      <Modal onClose={onClose} width='medium'>
        <Form onSubmit={handleInviteNewUser}>
          {({ formProps }) => (
            <form {...formProps}>
              <ModalHeader>
                <ModalTitle>
                  {t('userManagement.dialog.inviteUser.title')}
                </ModalTitle>
              </ModalHeader>
              <div style={{ padding: '0 24px' }}>
                <EmailField
                  name='email'
                  label={t('form.label.email')}
                  email={email}
                  setEmail={setEmail}
                  isDisabled={isInvitingUser}
                />
                <SelectMultipleField
                  name='siteAccess'
                  label={t('form.label.siteAccess')}
                  options={optionSites}
                  values={siteAccess}
                  setValues={setSiteAccess}
                  showMaxValue={2}
                  isDisabled={isInvitingUser}
                />
              </div>
              <ModalFooter>
                <Button appearance='subtle' onClick={onClose}>
                  {t('common.cancel')}
                </Button>
                <LoadingButton
                  isLoading={isInvitingUser}
                  appearance='primary'
                  type='submit'
                >
                  {t('userManagement.dialog.inviteUser.inviteButtonLabel')}
                </LoadingButton>
              </ModalFooter>
            </form>
          )}
        </Form>
      </Modal>
    </ModalTransition>
  );
}
