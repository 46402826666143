import React, { useContext, useState } from 'react';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';
import LoadingButton from '@atlaskit/button/loading-button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { siteAPIs } from '@root/services';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { ACTION_USER_TYPE_CONFIRM } from '@root/utils';
import mfaAPIs from '@root/services/mfaApis';
import { actions } from '../../redux';
import UserManagerContext from './UserManagerContext';
import { ALERT_TYPE } from '../common/MessageSnackbar';

export default function ActionUserConfirmModal({
  accountId,
  onClose,
  notification,
}) {
  const { t } = useTranslation();
  const { loadUsers, siteId } = useContext(UserManagerContext);
  const [isRemoving, setRemoving] = useState(false);
  const dispatch = useDispatch();

  const onClickCancel = () => {
    onClose();
  };

  const handleRemoveUser = async () => {
    setRemoving(true);
    newRelicUtils.addPageAction(PAGE_ACTION.userManagement.remove, {
      siteIds: siteId,
      targetIds: accountId,
    });
    try {
      await siteAPIs.removeUserFromSite(siteId, accountId);
      await loadUsers();
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.removeSiteUserOk',
        }),
      );
    } catch (err) {
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.error,
          messageKey: 'snackbar.inviteSiteUserFailed',
        }),
      );
    }
    setRemoving(false);
    onClose();
  };

  const handleResetDeviceMFA = async () => {
    setRemoving(true);
    newRelicUtils.addPageAction(PAGE_ACTION.userManagement.resetMFADevice, {
      siteIds: siteId,
      targetIds: accountId,
    });
    try {
      const body = {
        accountId,
      };
      await mfaAPIs.resetDeviceMFA(body);
      await loadUsers();
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.resetDeviceMFAOk',
        }),
      );
    } catch (error) {
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.resetDeviceMFAFailed',
        }),
      );
    }
    setRemoving(false);
    onClose();
  };
  const handleTurnOffMFARequire = async () => {
    setRemoving(true);
    newRelicUtils.addPageAction(PAGE_ACTION.userManagement.turnOffMFARequire, {
      siteIds: siteId,
      targetIds: accountId,
    });
    try {
      const body = {
        accountId,
        siteId,
      };
      await mfaAPIs.changeMfaRequiredSite(body);
      await loadUsers();
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.resetDeviceMFAOk',
        }),
      );
    } catch (error) {
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.resetDeviceMFAFailed',
        }),
      );
    }
    setRemoving(false);
    onClose();
  };

  const handleOnclick = (type) => {
    switch (type) {
      case ACTION_USER_TYPE_CONFIRM.REMOVE_USER:
        return handleRemoveUser();
      case ACTION_USER_TYPE_CONFIRM.RESET_DEVICE_MFA:
        return handleResetDeviceMFA();
      case ACTION_USER_TYPE_CONFIRM.TURN_OFF_MFA:
        return handleTurnOffMFARequire();
      default:
        return onClickCancel();
    }
  };

  return (
    <ModalTransition>
      <Modal>
        <ModalHeader>
          <ModalTitle appearance='danger'>{notification?.title}</ModalTitle>
        </ModalHeader>
        <ModalBody>{notification?.message}</ModalBody>
        <ModalFooter>
          <Button
            appearance='subtle'
            onClick={() => {
              handleOnclick(ACTION_USER_TYPE_CONFIRM.CANCEL_MODAL);
            }}
            isDisabled={isRemoving}
          >
            {t('common.cancel')}
          </Button>
          <LoadingButton
            autoFocus
            appearance='danger'
            onClick={() => {
              handleOnclick(notification?.type);
            }}
            isLoading={isRemoving}
          >
            {notification?.submitText}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
}
