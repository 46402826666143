import { atlasAPIs, siteAPIs } from '@root/services';
import React from 'react';

export const fetchCurrentUsers = async (siteId) => {
  const [{ data: siteUsersData }] = await Promise.all([
    siteAPIs.getSiteUsers(siteId),
  ]);
  let atlassianUsers = [];

  const siteUsersIds = Array.isArray(siteUsersData.siteUsers)
    ? siteUsersData.siteUsers
        .filter((i) => i.atlassianAccountId)
        .map((u) => u.atlassianAccountId)
    : [];
  try {
    const { data } = await siteAPIs.getUsersFromAtlassianByIds(
      siteId,
      siteUsersIds,
    );
    atlassianUsers = data.users;
  } catch (err) {
    // Do nothing
  }

  return {
    siteUsers: siteUsersData.siteUsers,
    atlassianUsers,
  };
};

const getAtlassianUserCountFromGroups = async (siteId, groupIds) => {
  const optsDs = groupIds.map((item) => ({
    headers: {
      siteid: siteId,
      Accept: 'application/json',
      relayapi: `/rest/api/3/group/member?groupId=${item}`,
      relaymethod: 'GET',
    },
  }));

  const promiseArr = optsDs.map((optsD) => atlasAPIs.proxyApi(optsD));
  const results = await Promise.all(promiseArr);

  const users = [];

  results.forEach((result) => {
    result.data.values
      .filter((user) => user.accountType === 'atlassian')
      .forEach((user) => {
        if (!users.includes(user?.accountId)) users.push(user?.accountId);
      });
  });
  return users.length;
};

export const getProductNames = async (siteId) => {
  const productNames = [];
  const optsD = {
    headers: {
      siteid: siteId,
      Accept: 'application/json',
      relayapi: '/rest/api/3/instance/license',
      relaymethod: 'GET',
    },
  };
  const raw = await atlasAPIs.proxyApi(optsD);
  const dataLicense = raw?.data?.applications;
  if (dataLicense) {
    const applicationRolesRaw = await siteAPIs.getAllApplicationRoles(siteId);
    const applicationRoleDataPromise = Array.isArray(applicationRolesRaw.data)
      ? applicationRolesRaw.data.map(async (item) => {
          const { plan } = dataLicense.find((p) => p.id === item.key);
          const { name } = item;
          const groups = item.groupDetails.map((group) => group.groupId);
          const userCount = await getAtlassianUserCountFromGroups(
            siteId,
            groups,
          );
          return {
            userCount,
            plan,
            name,
          };
        })
      : [];

    const listProducts = [
      'Jira Core',
      'Jira Software',
      'Jira Service Management',
      'Jira Service Desk',
    ];
    const applicationRoleData = await Promise.all(applicationRoleDataPromise);
    applicationRoleData.forEach((item) => {
      if (
        item.userCount >= 10 &&
        item.plan === 'FREE' &&
        listProducts.includes(item.name)
      )
        productNames.push(item.name);
    });
  }

  return productNames;
};

export const UserManagerDefault = {
  siteUsers: [],
  atlassianUsers: [],
};

const UserManagerContext = React.createContext(UserManagerDefault);

export default UserManagerContext;
