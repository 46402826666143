import { backEndApi } from '../utils';

const api = '/api/v1';
const sitePath = '/sites';
const siteAPIs = {
  getAll: () => backEndApi.get(`${api}${sitePath}`),
  getSiteUsers: (siteId) => backEndApi.get(`${api}${sitePath}/${siteId}/users`),
  // getAllSiteUsers: (body) => backEndApi.post(`${api}${sitePath}/sites/users`, body),
  getInvitator: (siteId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/invitator`),
  inviteUser: (siteId, body) =>
    backEndApi.post(`${api}${sitePath}/${siteId}/user`, body),
  inviteUserToManySites: (body) =>
    backEndApi.post(`${api}${sitePath}/user/invite`, body),
  removeUserFromSite: (siteId, accountId) =>
    backEndApi.delete(`${api}${sitePath}/${siteId}/user/${accountId}`),
  getAllApplicationRoles: (siteId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/application`),
  getAllSiteApplicationRoles: (body) =>
    backEndApi.post(`${api}${sitePath}/sites/application`, body),
  getUsersFromAtlassianByIds: (siteId, userIds) =>
    backEndApi.post(`${api}${sitePath}/${siteId}/users/bulk`, { userIds }),
  searchAtlassianUsers: (siteId, keyword) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/users/search?query=${keyword}`),
  getIssueFieldsFromAtlassian: (siteId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/fields/search`),
  getIssueCreatemeta: (siteId, projectIds) =>
    backEndApi.get(
      `${api}${sitePath}/${siteId}/issue/createmeta?projectIds=${projectIds.join(
        ',',
      )}`,
    ),
  getUserProjectFilters: (siteId, projectId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/${projectId}/filters`),
  updateUserProjectFilters: (data) =>
    backEndApi.put(
      `${api}${sitePath}/${data.siteId}/${data.projectId}/filters`,
      data.body,
    ),
  resetUserPasswordFromSite: (siteId, accountId) =>
    backEndApi.put(
      `${api}${sitePath}/${siteId}/user/reset-password?accountId=${accountId}`,
    ),
  deleteProjectSpaceLink: (siteId, key) =>
    backEndApi.delete(`${api}${sitePath}/${siteId}/link/${key}`),
  checkPermissionConfluence: (siteIds) =>
    backEndApi.get(`${api}${sitePath}/confluence-available?siteIds=${siteIds}`),
  checkProductPermissionConfluence: (siteId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/confluence-available`),
  checkGlobalSharing: (siteIds) =>
    backEndApi.get(`${api}${sitePath}/checkGlobalSharing?siteIds=${siteIds}`),
  checkDeletedSite: ({ siteId }) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/checkDeleted`),
  checkNewUpdateStore: (siteIds) =>
    backEndApi.get(
      `${api}${sitePath}/check-new-update-store?siteIds=${siteIds.join(',')}`,
    ),
  updateOrganizationId: (body) =>
    backEndApi.post(`${api}${sitePath}/update-organization-id`, body),
};
export default siteAPIs;
